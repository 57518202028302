import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import { ADDRESS_BASE_FRAGMENT } from '@/graphql/_Fragments/Address/Base';
import { CATEGORY_WITH_PRODUCT_FRAGMENT } from '@/graphql/_Fragments/Category/WithProduct';
import DomainContextParams from '@/utils/types/DomainContextParams';

export const EXHIBITOR_CARD_WITH_EDITION_FRAGMENT = `
  fragment exhibitorCardWithEditionFragment on Exhibitor {
    id
    uid
    name
    featured
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    logoFileResource {
      ...fileResourceBaseFragment
    }
    categoriesInContext(context: "${DomainContextParams.companyCard}") {
      ...categoryWithProductFragment
    }
    addresses {
      ...addressBaseFragment
    }
    country {
      ...countryBaseFragment
    }
    _actions(actions: ["CAN_BOOKMARK"]) {
       value
       key
    }
    editionMappings {
      editionExhibitor {
      schemaCode
        booths {
          schemaCode
          number
          exhibitHall {
            id
            uid
            name
          }
          geozone {
            schemaCode
            id
            exhibitHall {
              id
              name
            }
          }
        }
      }
    }
    _isBookmarked(myUid: "%authUser%")
    _isVisited(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${CATEGORY_WITH_PRODUCT_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
  ${ADDRESS_BASE_FRAGMENT}
`;
